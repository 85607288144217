import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO title="Thank your for your RSVP!" />
      <div className="text--center">
        <h1>Thank You for your RSVP!</h1>
        <h2>
          We look forward to seeing you on July 19th. for our Annual Media
          Tailgate
        </h2>
      </div>
    </Layout>
  )
}

export default ThankYouPage
